import store  from '../store';
import db from '@/plugins/dexie';

export default async function notaatt() {
  // Atualização das notas que foram emitidas enquanto offline
    const processando = await db.notafiscal.where({ nofprocessamento: 'EM PROCESSAMENTO' });
    const semcodigo = [];
    for (const item of (await processando.toArray())) {
      if (item.nofcodigoretornanfesefaz == '100') {
        await db.notafiscal.where({ id: item.id }).modify({nofprocessamento: 'EMITIDO'});
      } else if(item.nofcodigoretornanfesefaz !== '100' && item.nofcodigoretornanfesefaz) {

        await db.notafiscal.where({ id: item.id }).modify({nofprocessamento: 'ERRO'});
      } else {
        semcodigo.push(item)
      }
    }
    if(semcodigo.length > 0) {
      store.dispatch('notafiscal/verificarEmissao', { body: semcodigo });
    }

}