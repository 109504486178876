<template>
  <div v-if="!loading" class="wrapper">
    <div v-if="valores.length === 0"
    class="semabastecimento">
    <br/>
    Não foi encontrado nenhum abastecimento
    </div>
    <div class="divnovo">
      <v-btn height=45 color="green botaoinferior" @click="$_novoFormulario">
        Novo
      </v-btn>
    </div>
    <div v-for="item in valores" :key="item.abaidabastecimento" ref="lista">
      <app-card-abastecimento :value="item" @innerClick="$_onClickCard" class="card">
      </app-card-abastecimento>
    </div>
  </div>
</template>

<script>

// import errorHandler from '@/utils/error-handler';

import deepEquals from '@/utils/deep-equals';

import { getQueryValue, setQueryValue } from '@/services/query-string';

import { ABASTECIMENTO_QUERIES } from '@/constants/QUERY_FILTERS';

import { ROUTE_CADASTRO, ROUTE_READONLY } from '@/constants/ROUTES';

import { ABASTECIMENTO_STORAGE, ROTA_STORAGE } from '@/constants/STORAGE';

import db from '@/plugins/dexie';

import AppCardAbastecimento from '../../components/app/card/abastecimento/Index.vue';
// import AppCardAtividadeSkeleton from '../../components/app/card/atividade/Skeleton.vue';
import { SetupWebSocket } from '../../utils/ws/websocket';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: 'ViewFrota',

  components: {
    AppCardAbastecimento,
  },

  data() {
    return {
      rotaFiltros: [
        ROUTE_CADASTRO,
      ],

      loading: true,

      filters: {
        createdFrom: getSessionStorage(ABASTECIMENTO_STORAGE.createFrom, null),
        createdBefore: getSessionStorage(ABASTECIMENTO_STORAGE.createBefore, null),
      },
    };
  },

  methods: {
    async $_load() {
      this.loading = true;
      const emAndamento = await getSessionStorage(ABASTECIMENTO_STORAGE.frota);
      this.retomar = !!(emAndamento);
      console.log(new Date(this.filters.createdFrom), new Date(`${this.filters.createdBefore}T23:59:00`))
      try {
        this.valores = await db.abastecimento.where('abadata').between(
          new Date(this.filters.createdFrom), new Date(`${this.filters.createdBefore}T23:59:00`)
        ).toArray();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }


      return null;
    },

  //   table.where(indexOrPrimKey)
  // .between(lowerBound, upperBound, includeLower, includeUpper)

    async $_novoFormulario() {
      sessionStorage.setItem(ROTA_STORAGE.cadastro, 'abastecimento');
      this.$router.replace({
        ...this.$route,
        name: ROUTE_CADASTRO.name,
      });
    },

    async $_retomarFormulario() {
      this.retomar = false;
      this.$router.replace({
        ...this.$route,
        name: ROUTE_CADASTRO.name,
      });
    },

    async $_onClickCard(e) {
      sessionStorage.setItem(ABASTECIMENTO_STORAGE.idabastecimento, e);
      this.$router.replace({
        ...this.$route,
        name: ROUTE_READONLY.name,
      });
    },
  },

  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {

        const filters = {
          createdFrom: getQueryValue(ABASTECIMENTO_QUERIES.createdFrom, this.filters.createdFrom),
          createdBefore: getQueryValue(
            ABASTECIMENTO_QUERIES.createdBefore,
            this.filters.createdBefore,
          ),
        };

        if (!deepEquals(this.filters, filters)) {
          this.filters.createdFrom = filters.createdFrom;
          this.filters.createdBefore = filters.createdBefore;

          if (this.$refs.lista) {
            this.$_load();   
          }
        }
      },
    },

    options: {
      deep: true,
      handler() {
        setQueryValue(ABASTECIMENTO_QUERIES.createdFrom, this.filters.createdFrom);
        setQueryValue(ABASTECIMENTO_QUERIES.createdBefore, this.filters.createdBefore);
      },
    },
  },

  created() {

    this.$_load();
    SetupWebSocket();
  },
};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }

  .center {
    width: 100%;
    text-align: center;
  }

  .divnovo {
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }

  @media screen and (min-width:600px) {
    .tablet {
      display: flex;
    }
  }

  .semabastecimento {
    text-align: center;
  }

  .botaoinferior {
    color: white;
  }

  .card {
    padding: 3px;
    margin: 15px 10px 0px 10px
  }

  .wrapper {
  }

  .btnretomar {
    margin-right: 12px;
  }

</style>
