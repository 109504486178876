<template>
  <layout-default-appbar filterable>
    Nota Fiscal
  </layout-default-appbar>
</template>

<script>
import LayoutDefaultAppbar from '@/layouts/default/AppBar.vue'

export default {
  name: 'ViewAppBarNFe',

  components: {
    LayoutDefaultAppbar,
  },

  data() {
    return {
    };
  },
};
</script>
