<template>
  <core-view class="view" fill-height>
    <template>
    <div class="center" v-if="!loading">
      <v-form ref="form" lazy-validation>
        <div>
          <div class="linha">
            <btn-auto-complete
            :rules="rules.obrigatorio"
            validate-on-submit
            :returnObject="false" :dense="true"
            label="Natureza"
            :dados="autocomplete.natureza" icon="mdi-chevron-down"
            v-model="formulario.natureza" item-label="naodescricao" 
            item-value="naoidnaturezaoperacao"
            >
            </btn-auto-complete>
          </div>
          <div class="linha">
          <multi-field
            :rules="rules.obrigatorio"
            labelTexto="Pessoa"
            v-model="formulario.pessoa"
            :load="autocomplete.pessoa_transportador"
            :filterString="true"
            itemlabel="pesnome"
            itemvalue="pesidpessoa"
            itemdetail="pesnomefantasia"
            :radiolabel="['Codigo', 'Nome']"
            @filtro="$_onFiltro($event, 'pessoa')"
            />
          </div>
          <div class="linha">
            <multi-field
            :rules="rules.obrigatorio"
            labelTexto="Produto"
            v-model="returnObject.produto"
            :load="autocomplete.produto"
            itemlabel="prodescricao"
            :filterString="true"
            itemvalue="procodigo"
            itemdetail="proidproduto"
            :radiolabel="['Codigo', 'Nome']"
            @filtro="$_onFiltro($event, 'produto')"
            @change="$_toProduto($event, 'produto')"/>
          </div>
          <div class="linha">
            <v-text-field label="UN"
            :rules="rules.obrigatorio" validate-on-blur
            outlined v-model='formulario.un'
            @input="formulario.un = formulario.un.toUpperCase()"
            background-color="white" dense
            >
            </v-text-field>

            <v-currency-field label="Quantidade"
            :decimalLength="3"
            :rules="rules.nulo"
            maxlength=10
            outlined v-model="quantidade"
            background-color="white" dense
            @input="$_toQuantidade()" >
            </v-currency-field>
          </div>
          <div class="linha">
            <v-currency-field label="Vl Unitario"
            :decimalLength="4"
            :rules="rules.nulo"
            maxlength=11
            outlined v-model="vlunitario"
            background-color="white" dense
            @input="$_toValorUnitario" >
            </v-currency-field>

            <v-text-field label="Total"
            :rules="rules.exorbitante"
            @focus.prevent
            maxlength=16
            readonly dense outlined
            background-color="white" v-model="$_total">
            </v-text-field>
          </div>
          <div class="linha">
          <multi-field
            :rules="rules.obrigatorio"
            labelTexto="Transportador"
            v-model="formulario.transportador"
            :load="autocomplete.pessoa_transportador"
            :filterString="true"
            itemlabel="pesnome"
            itemvalue="pesidpessoa"
            itemdetail="pesnomefantasia"
            :radiolabel="['Codigo', 'Nome']"
            @filtro="$_onFiltro($event, 'transportador')"
            />
          </div>
          <div class="linha">
            <btn-auto-complete label="Tipo Frete"
            :rules="rules.obrigatorio"
            :dados="[{descricao: 'CIF'}, {descricao: 'FOB'}]" icon="mdi-chevron-down"
            v-model="formulario.tipofrete" itemLabel="descricao"
            :returnObject="false" :dense="true"
            item-value="descricao"
            >
            </btn-auto-complete>
            <btn-auto-complete label="Placa"
            :rules="rules.obrigatorio"
            :dados="autocomplete.placa" icon="mdi-chevron-down"
            v-model="returnObject.placa" item-label="veiplaca"
            :returnObject="true" :dense="true"
            item-value="veiidveiculo" 
            @change="$_toPlaca($event)"
            >
            </btn-auto-complete>
          </div>
          <br/>
          <div class="linha">
            <v-text-field label="Espécie"
            :rules="rules.obrigatorio"
            v-model="formulario.especie"
            background-color="white" outlined dense
            >
            </v-text-field>
          </div>
        </div>
        <br/>
        <br/>
        <div class="divinferior">
          <v-btn height=45 color="green botaoinferior" v-if="!cadastro" @click="$_salvaDados()">
            Cadastrar
          </v-btn>
          <v-btn v-else height=45 class="green botaoinferior disable-click">
            <v-progress-circular
              indeterminate
              color="white" >
            </v-progress-circular>
          </v-btn>
        </div>
      </v-form>
    </div>
  </template>
</core-view>
</template>

<script>
// import errorHandler from '@/utils/error-handler';

import {
  ROUTE_NFE,
} from '@/constants/ROUTES';

import { NFE_STORAGE } from '@/constants/STORAGE';

import CoreView from '@/components/core/view/Index.vue';

import BtnAutoComplete from '@/components/core/btn-auto-complete/Index.vue';
import MultiField from '@/components/core/multi-field/Index.vue';
import errorHandler from '@/utils/error-handler';

import geraData from '@/utils/geradata';
// import moment from 'moment-timezone';

import db from '@/plugins/dexie';

import { WsEnviaDados } from '../../utils/ws/websocket';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: 'ViewNFe',

  components: {
    CoreView,
    BtnAutoComplete,
    MultiField,
  },

  data() {
    return {
      rules: {
        obrigatorio: [v => !!v || 'Este campo é obrigatório.'],
        nulo: [
          v => !!v || 'Este campo é obrigatório.',
          v => v != "0,000" || 'Este valor não pode ser nulo.',
          v => v != "0,0000" || 'Este valor não pode ser nulo.',
        ],
        exorbitante: [
          v => !!v || 'Este campo é obrigatório.',
          v =>
          {
            let valorMáximo = v.replace(/\./g, '').replace(',', '.')
            return valorMáximo <= 100000 || 'Este valor é muito alto.'
          }
        ]
      },
      formulario: {
        natureza: null,
        pessoa: null,
        produto: null,
        un: null,
        quantidade: null,
        vlunitario: null,
        total: null,
        transportador: null,
        tipofrete: null,
        placa: null,
        especie: null,
      },
      returnObject: {
        produto: null,
        placa: '',
      },

      quantidade: null,
      vlunitario: null,

      autocomplete: {
        natureza: null,
        produto: null,
        pessoa_transportador: null,
        placa: null,
      },

      loading: true,
      cadastro: false,
    };
  },
  computed: {
    $_total() {
      let quantidade = this.quantidade
      let vlunitario = this.vlunitario
      let totalFormatado;
      if(typeof quantidade === 'string' || typeof vlunitario === 'string' ){
        if(typeof quantidade === 'string'){
          quantidade = quantidade.replace(',', '.') 
        }
        if(typeof vlunitario === 'string'){
          vlunitario = vlunitario.replace(',', '.') 
        }
        const total = parseFloat(quantidade) * parseFloat(vlunitario);
        totalFormatado = new Intl.NumberFormat("pt-BR", {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        }).format(total)
        
      } else {
        const total = quantidade * vlunitario;
        totalFormatado = new Intl.NumberFormat("pt-BR", {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        }).format(total)
        
      }

      if (totalFormatado !== "NaN" && totalFormatado !== '0,0000') {
        return totalFormatado
      }
      return "";
    }
  },

  methods: {

    async $_salvaDados() {
      /* let now = moment();
      now = now.subtract(3,'hours'); */
      this.cadastro = true;
/*       if (this.cadastro === true) {
        return
      } */
      if (this.$refs.form.validate()) {
       const idnuvem = getSessionStorage('user:useidnuvem', null);

        const novoNfeNuvem = {
          nofidnaturezaoperacao: this.formulario.natureza,
          nofidpessoa: Number(this.formulario.pessoa),
          nofun: this.formulario.un,
          nofquantidade: String(this.formulario.quantidade).replace(',', '.'),
          nofvalorunitario: String(this.formulario.vlunitario).replace(',', '.'),
          noftotal: this.$_total.replace('.', '').replace(',', '.'),
          nofidtransportador: this.formulario.transportador,
          noftipofrete: this.formulario.tipofrete,
          nofidveiculo: this.formulario.placa.veiidveiculo,
          nofespecie: this.formulario.especie,
          nofdataatualizacao: new Date().toISOString(),
          nofplaca: this.formulario.placa.veiplaca,
          nofidproduto: String(this.formulario.produto.proidproduto),
          nofnomepessoa: null,
          nofnometransportador: null,
          nofidnuvem: 'null',
          nofidusuario: idnuvem,
          nofdatacriacao: geraData(),
        };
        novoNfeNuvem.nofnomepessoa = await db.pessoa.where({ pesidpessoa: Number(this.formulario.pessoa) }).first();
        novoNfeNuvem.nofnometransportador = await db.pessoa.where({ pesidpessoa: Number(this.formulario.transportador) }).first();

        novoNfeNuvem.nofnomepessoa = novoNfeNuvem.nofnomepessoa.pesnome;
        novoNfeNuvem.nofnometransportador = novoNfeNuvem.nofnometransportador.pesnome;
        const nfedb = {...novoNfeNuvem, nofprocessamento: 'EMITIR'};
        db.notafiscal.add(nfedb);
        WsEnviaDados({ tipo: 'postNotaFiscal', msg: [novoNfeNuvem] });
        sessionStorage.removeItem(NFE_STORAGE.formulario);

        this.$router.push({
          ...this.$route,
          name: ROUTE_NFE.name,
        });
      } else {
        errorHandler('Alguns campos não foram preenchidos');
      }
      this.cadastro = false;
    },

    async $_findDb() {
      // Transformação do campo de pesidpessoa para String, para melhor utilização do Multi-field
      const pessoa = await db.pessoa.toArray();
      this.autocomplete.pessoa_transportador = pessoa.map((item) => {
        return {...item, pesidpessoa: String(item.pesidpessoa)}
      });
      this.autocomplete.natureza = await db.naturezaoperacao.toArray();
      this.autocomplete.produto = await db.produto.toArray();
      this.autocomplete.placa = await db.veiculo.toArray();
      this.autocomplete.notafiscalitem = await db.notafiscalitem.toArray();
      this.loading = false;
      return null;
    },

    $_onFiltro(e, campo) {
      this.formulario[campo] = e;
    },

    async $_setupCampos() {
      if (!sessionStorage.getItem(NFE_STORAGE.formulario)) {
        await this.$_formToStorage();
      } else {
        this.formulario = JSON.parse(sessionStorage.getItem(NFE_STORAGE.formulario, this.formulario));
        this.returnObject.produto = this.formulario.produto.procodigo ?? '';
        this.returnObject.placa = this.formulario.placa ?? '';
        this.quantidade = this.formulario.quantidade ?? '';
        this.vlunitario = this.formulario.vlunitario ?? '';
      }
    },

    async $_formToStorage(){
      this.formulario.total = this.$_total;
      sessionStorage.setItem(NFE_STORAGE.formulario, JSON.stringify(this.formulario))
    },
    
    async $_toValorUnitario() {
      this.formulario.vlunitario = String(this.vlunitario).replace('.', ',') ?? '';
    },

    async $_toQuantidade() {
      this.formulario.quantidade = String(this.quantidade).replace('.', ',') ?? '';
    },

    async $_toProduto(event){
      // Para evitar mudar o componente do Multi-field, faço um select aqui com o Procodigo, já que é único
      const obj = await db.produto.where({procodigo: String(event) }).first()
      this.formulario.produto = {
        proidproduto: obj.proidproduto,
        prodescricao: obj.prodescricao,
        procodigo: obj.procodigo
      }
    }, 

    async $_toPlaca(event){
      this.formulario.placa = {
        veiidveiculo: event.veiidveiculo,
        veiplaca: event.veiplaca,
      }
    }
  },

  watch: {
    formulario: {
      deep: true,
      handler() {
        this.$_formToStorage();    
      }
    },
  },

  created() {
    this.$_findDb();
    this.$_setupCampos();
  },
};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }

  .center {
    width: 100%;
    text-align: center;
  }

  .linha {
    display: flex;
  }

  .divinferior {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 5px;
    right: 0%;
  }

  .botaoinferior {
    color: white;
  }

  .botaomargim {
    margin-right: 15%;
  }

  @media screen and (min-width:600px) {
    .tablet {
      display: flex;
    }
  }
  .view{
    max-width: 450px;
  }
  .disable-click{
    pointer-events: none;
  }
</style>
