import db from "@/plugins/dexie";
import { WsEnviaDados } from "../../websocket";


export default {
    enviaVisitaTecnica: () => {
        const visitas = db.visitatecnica.where('vitidnuvem').equals('null').toArray();
        visitas.then((tabela) => {
            tabela.forEach((vit) => {
            WsEnviaDados({tipo: 'postVisitaTecnica', msg: vit})
            });
        });
    },
    retornoVisitaTecnica: ({ data }) => {
        const item = data.body;
        console.log('item', item)
        // query pela DataCriacao para fazer um handshake entre a WS para atribuir o idnuvem no lancamento
        const queryPromise = db.visitatecnica.where('vitdatacriacao').equals(String(item.vitdatacriacao)).toArray();
        queryPromise.then((tabela) => {
        tabela.forEach((linha) => {
            db.visitatecnica.update(linha.id, {vitidnuvem: item.id})
            console.log(linha)
            })
        })
    },
}
