export const ROUTE_HOME = {
  path: '/home',
  name: 'home',
};

export const ROUTE_ABOUT = {
  path: '/about',
  name: 'about',
};

export const ROUTE_CADASTRO = {
  path: '/abastecimento/cadastro',
  name: 'cadastro',
};

export const ROUTE_SIGN_IN = {
  path: '/sing-in',
  name: 'sign-in',
};

export const ROUTE_ABASTECIMENTO = {
  path: '/abastecimento',
  name: 'abastecimento',
};

export const ROUTE_APONTAMENTO = {
  path: '/apontamento',
  name: 'apontamento',
};

export const ROUTE_AP_CADASTRO = {
  path: '/apontamento/cadastro',
  name: 'apcadastro',
};

export const ROUTE_VISITA = {
  path: '/visita',
  name: 'visita'
}

export const ROUTE_VISITA_CADASTRO = {
  path: '/visita/cadastro',
  name: 'visitacadastro',
}

export const ROUTE_VISITA_INFO = {
  path: '/visita/info',
  name: 'visitainfo'
}

export const ROUTE_CAMERA = {
  path: '/visita/camera',
  name: 'camera'
}

export const ROUTE_CADASTRO_NFE = {
  path: '/cadastronfe',
  name: 'cadastronfe',
};

export const ROUTE_NFE = {
  path: '/nfe',
  name: 'nfe',
};
// Filtros =============

export const ROUTE_FROTA = {
  path: '/abastecimento/cadastro/frota',
  name: 'frota',
};

export const ROUTE_OPERADOR = {
  path: '/abastecimento/cadastro/operador',
  name: 'operador',
};

export const ROUTE_ORIGEM = {
  path: '/abastecimento/cadastro/origem',
  name: 'origem',
};

export const ROUTE_PROPRIEDADE = {
  path: '/abastecimento/cadastro/propriedade',
  name: 'propriedade',
};

export const ROUTE_ATIVIDADE = {
  path: '/abastecimento/cadastro/atividade',
  name: 'atividade',
};

export const ROUTE_READONLY = {
  path: '/abastecimento/visualizar',
  name: 'readonly',
};

export const ROUTE_READ_ATIVIDADE = {
  path: '/apontamento/atividade',
  name: 'readatividade'
};