<template>
  <component
    v-model="isActive"
    ref="component"
    :is="$_component"
    :return-value.sync="$_model"
  >
    <template #activator="{ on, attrs }" v-if="!readonly">
      <core-text-field 
        readonly
        :value="$_presentationModel"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
        outlined
        :rounded="!form"
        :style="{ 'background-color': (form) ? 'white' : 'none'  }"

        append-icon="mdi-calendar-outline"

        @click:append="$_onClickAppendIcon"
      />
    </template>
    <template #readonly v-else>
      <core-text-field 
        readonly
        :value="$_presentationModel"
        v-bind="{ ...$attrs }"
        hide-details
        outlined
        :rounded="!form"
        :style="{ 'background-color': (form) ? 'white' : 'none'  }"
        append-icon="mdi-calendar-outline"
      />
    </template>

    <v-date-picker scrollable
      v-model="$_model"
      :no-title="!$_isMobile"
      locale="pt-br"
    >
      <v-spacer />

      <v-btn rounded depressed
        class="pr-3"
        @click="$_onClickCancel"
      >
        <v-icon left>mdi-close</v-icon>
        Cancelar
      </v-btn>

      <v-btn rounded depressed
        color="primary"
        class="pr-3"
        @click="$_onClickConfirm"
      >
        <v-icon left>mdi-check</v-icon>
          Ok
      </v-btn>
    </v-date-picker>
  </component>
</template>

<script>
import CoreTextField from '../text-field/Index.vue';
import CoreDatePickerFieldMobile from './Mobile.vue';
import CoreDatePickerFieldDesktop from './Desktop.vue';


export default {
  name: 'CoreDatePickerField',

  components: {
    CoreTextField,
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  props: {
    value: String,
    form: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: false,
    };
  },

  computed: {
    $_isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    $_component() {
      return this.$_isMobile ? CoreDatePickerFieldMobile : CoreDatePickerFieldDesktop;
    },

    $_model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    $_presentationModel() {
      const date = new Date(this.value);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      return date.toLocaleDateString();
    },
  },

  methods: {
    $_onClickCancel() {
      this.isActive = false;
    },

    $_onClickConfirm() {
      this.$refs.component.save(this.$_model);
    },

    $_onClickAppendIcon() {
      this.isActive = true;
    },
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
};
</script>
