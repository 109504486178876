<template>
  <component
    v-model="isActive"
    ref="component"
    :is="$_component"
    :return-value.sync="$_model"
  >
    <template #activator="{ attrs }" v-if="!readonly">
      <core-text-field
        v-model="valor"
        v-bind="{ ...attrs, ...$attrs }"
        outlined
        :rounded="!form"
        @keydown="$_onKeyDown"
        @blur="$_onBlur"
        :style="{ 'background-color': (form) ? 'white' : 'none'  }"
        type="tel"
        append-icon="mdi-clock-time-eight-outline"
      />
    </template>
    <template #readonly v-else>
      <core-text-field 
        readonly
        :value="$_presentationModel"
        v-bind="{ ...$attrs }"
        hide-details
        outlined
        :rounded="!form"
        :style="{ 'background-color': (form) ? 'white' : 'none'  }"

        append-icon="mdi-calendar-outline"
      />
    </template>
    <!-- <div class="titulo">
      <br/>
      <div>
        <input 
        class="input"
        height="42px"
        min=0
        max=23
        type="number"
        ref="dateinput"
        readonly
        @click="$_changeStep(1)"
        @change="$_updateDataHeader(true)"
        @keydown="$_dataKeyDown"
        v-model="data"/>
      </div>
      <div class="middle">
          :
      </div>
      <div>
        <input 
        type="number"
        class="input"
        height="42px"
        min=0
        max=59
        maxlength="2"
        readonly
        ref="timeinput"
        @click="$_changeStep(2)"
        @change="$_updateDataHeader(false)"
        @keydown="$_timeKeyDown"
        @keyup.enter="$_onClickConfirm"
        v-model="time"/>
      </div>
    </div> -->

    <v-time-picker 
      scrollable
      v-model="$_model"
      ref="timepicker"
      format="24hr"
      locale="pt-br"
    >
      <v-spacer />

      <v-btn rounded depressed
        class="pr-3"
        @click="$_onClickCancel"
      >
        <v-icon left>mdi-close</v-icon>
        Cancelar
      </v-btn>

      <v-btn rounded depressed
        color="primary"
        class="pr-3"
        @click="$_onClickConfirm"
      >
        <v-icon left>mdi-check</v-icon>
          {{show}}
      </v-btn>
    </v-time-picker>
  </component>
</template>

<script>
import CoreTextField from '../text-field/Index.vue';
import CoreTimePickerFieldMobile from './Mobile.vue';
import CoreTimePickerFieldDesktop from './Desktop.vue';

export default {
  name: 'CoreTimePickerField',

  components: {
    CoreTextField,
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  props: {
    value: String,
    form: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: false,
      data: null,
      time: null,
      show: null,
      //
      valor: null,
    };
  },

  computed: {
    $_isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    $_component() {
      return this.$_isMobile ? CoreTimePickerFieldMobile : CoreTimePickerFieldDesktop;
    },

    $$_inputModel() {
      return `${this.data}:${this.time}`;
    },

    $_model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    $_presentationModel() {
      return this.value
    },
  },

  methods: {
    $_onClickCancel() {
      this.isActive = false;
    },

    $_onClickConfirm() {
      this.$refs.component.save(this.$_model);
    },

    $_onClickAppendIcon() {
      this.isActive = true;
    },

    $_updateDataHeader(dateOnly) {
      if (this.data > 23) {
        this.data = 23;
      }
      if (this.data.length === 1) {
        this.data = `0${this.data}`
      }
      if (!this.data) {
        this.data = '00'; 
      }
      if (!dateOnly) {
        console.log('Hello');
        if (this.time > 59) {
          this.time = 59;
        }
        if (this.time.length === 1) {
          this.time = `0${this.time}`
        }
        if (!this.time) {
          this.time = '00'; 
        }
      }
      this.$_model = this.$$_inputModel;
    },

    $_onKeyDown(e) {
      if (!Number(e.key) && (e.key !== 'Backspace') && (e.key !== '0') && (e.key !== 'Unidentified')) {
        e.preventDefault();
        return;
      }
      if (e.key === 'Backspace') {
        if (this.valor === 4) {
          e.preventDefault();
          this.valor = e.target.value.slice(0,-2)
        }
      } else {
        if (e.key === 'Unidentified') {
          if (this.valor.length > 4) {
            setTimeout(() => {
              this.valor = this.valor.slice(0, 5);
            })
          }
          if (this.valor.length === 2) {
            this.valor = this.valor + ':'
          }
          setTimeout(() => {
            this.valor = this.valor.replace(/[^0-9:]/g, '');
          }, 0);
          return;
        }
        if (e.target.value.length === 5) {
          e.preventDefault();
          return;
        }
        if (e.target.value.length === 2) {
          e.target.value = e.target.value + ':'
        }
      }
    },

    $_onBlur(e) {
      let horas = e.target.value.slice(0, 2);
      let minutos = e.target.value.slice(3, 5);
      
      horas = (Number(horas) > 0) ? horas: '00';
      minutos = (Number(minutos) > 0) ? minutos : '00';

      horas = (Number(horas) <  10) ? `0${Number(horas)}` : horas;
      minutos = (Number(minutos) < 10) ? `${Number(minutos)}0` : minutos;

      horas = (Number(horas) > 23) ? 23 : horas;
      minutos = (Number(minutos) > 59) ? 59 : minutos;

      e.target.value = `${horas}:${minutos}`;
      this.valor = `${horas}:${minutos}`;
      this.$_model = `${horas}:${minutos}`;
    }
  },

  created() {
    this.valor = this.$_presentationModel;
  },

  mounted() {
    // this.$refs.dateinput.el.focus();
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
};
</script>

<style scoped>
  .titulo {
    display: flex;
    justify-content: center;
    width: 100%;
    color: white;
    background-color: #4CAF50;
  }

  .input, .middle {
    font-size: 2.7rem;
    font-weight: bold;
  }

  .middle {
    transform: translateY(-2px);
  }

  .input {
    color: white;
    width: 49px;
  }

  .input:focus {
    outline: none;
  }
</style>