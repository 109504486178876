<template>
  <div>
    <div class='radios opacity-switch'>
        <v-radio-group v-model="radioGroup" row>
          <v-radio
            v-for="n in 2"
            :key="n"
            :label="label[n - 1]"
            :value="n"
          ></v-radio>
        </v-radio-group>
      </div>
      <core-text-field clearable class='txt-filter' @focus="$_subirFormInterno(1)"
        v-model="input"
        @click:clear="$_clear()"
        :label="tabela[0].toUpperCase() + tabela.slice(1)"
        :type="inputType"
      />
      <div class='btns-wrap'>
        <v-btn rounded color='primary' class='btns opacity-switch' @click='$_load(0)'>
          <v-icon>mdi-arrow-left-thick</v-icon>
        </v-btn>
        <v-btn rounded color='primary' ref="botaolike" :class="{'disable-click': disableMiddle }" class='btns opacity-switch' @click='$_load(1)'>
          <v-icon>mdi-arrow-left-thick</v-icon><v-icon>mdi-arrow-right-thick</v-icon>
        </v-btn>
        <v-btn rounded color='primary' class='btns-mini opacity-switch' @click='$_load(2)'>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <template>
        <div
          class="mx-auto cardlist"
          max-width="500"
          v-if='filtroInicial'
        >
          <v-list two-line class='scroll'>
            <v-list-item-group
              active-class="green--text"
              multiple
            >
              <div v-for="(item, index) in items" :key="index">
                  <slot name="card" :item="item"/>
                  <v-divider
                    v-if="index < items.length - 1"
                    :key="index"
                  ></v-divider>
              </div>
            </v-list-item-group>
          </v-list>
        </div>
        <v-divider></v-divider>
    </template>
  </div>
</template>

<script>
import CoreTextField from '@/components/core/text-field/Index.vue';
import errorHandler from '@/utils/error-handler';
import db from '@/plugins/dexie';

export default {
  name: 'EmbeddedFilter',
  components: {
    CoreTextField,
  },
  props: {
    tabela: {
      type: String,
      required: true,
    },
    campos: {
      type: Array,
      required: true,
    },
    label: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      disableMiddle: false,
      input: null,
      valorFinal: null,
      anterior: {
        parametro: null,
        descricao: null,
        codigo: null,
      },
      radioGroup: 2,
      filtroInicial: true,
      items: null,
      loading: true,
      inputType: 'text'
    };
  },

  methods: {
    async $_clear() {
    this.input = null;
    this.valorFinal = null;
    this.$emit('limpar', null);
    return;
    },

    async $_load(parametroLoad) {
      this.loading = true;
      const ant = this.anterior;
      try {
        if ((typeof this.input) === 'string') {
          this.valorFinal = this.input.toUpperCase();
        } else {
          this.valorFinal = this.input;
        }

        // Evitar requests repetidos.
        if (parametroLoad === ant.parametro && this.input === ant.descricao) {
          return;
        }
        this.anterior.parametro = parametroLoad;
        this.anterior.parametro = this.input;

        if (parametroLoad === 2) {
          this.input = null;
          this.valorFinal = null;
          this.$emit('limpar', null);
          this.$_subirFormInterno(0);
          return;
        }

        if (this.input) {
          if (this.radioGroup === 2) {
            if (parametroLoad === 0) {
              this.items = await db[this.$props.tabela].where(this.$props.campos[1]).startsWith(this.valorFinal).toArray();
            } else {
              this.items = await db[this.$props.tabela].filter((item) => { return new RegExp(this.valorFinal).test(item[this.$props.campos[1]]); })
                .toArray()
            }
            console.log('what');
          } else {
            if (!Number(this.input)) {
              errorHandler('O valor informado não é um numero!');
              return
            }
            this.items = await db[this.$props.tabela].where({ [this.$props.campos[0]]: Number(this.input) }).toArray();
          }
        } else {
          this.items = null;
        }
        console.log(this.items, 'items');
        return;
      } catch (err) {
        console.log(err);
        errorHandler.call(this, err);
      } finally {
        this.loading = false;
      }
    },

    $_subirFormInterno(bool) {
      const opacity = document.querySelectorAll('.opacity-switch');
      this.$emit('subirform', { bool, filtro: this.filtroInicial })
      if (bool) {
        if (this.filtroInicial) { return; }
        for (let i = 0; i < opacity.length; i += 1) {
          opacity[i].style.opacity = '1';
        }

        this.filtroInicial = true;
      } else {
        if (!this.filtroInicial) { return; }
        for (let i = 0; i < opacity.length; i += 1) {
          opacity[i].style.opacity = '0';
        }
        this.filtroInicial = false;
      }
    },

    $_setValue(valor) {
        this.input = valor;
    },
  },
  watch: {
    radioGroup: {
      handler() {
        this.nomepessoa = '';
        if (this.radioGroup === 1) {
          this.inputType = 'tel';
          this.$refs.botaolike.$el.style.opacity = '0.5';
          this.disableMiddle = true;
        } else {
          this.inputType = 'text';
          this.$refs.botaolike.$el.style.opacity = '1';
          this.disableMiddle = false;
        }
        this.$_load();
      },
    },
  }
};
</script>

<style scoped>
  .radios {
    transition: opacity 0.3s;
    opacity: 0;
  }

  .opacity-switch {
    transition: opacity 0.3s;
  }

  
  .btns-wrap {
    display: flex;
    justify-content: space-around;
    height: 50px;
  }

  .btns {
    width: 33%;
    transition: opacity 0.3s;
    opacity: 0;
  }

  .btns-mini {
    width: 10%;
    transition: opacity 0.3s;
    opacity: 0;
  }

  .mx-auto.cardlist {
    transition: opacity 0.3s;
    transition: top 0.4s;
    height: 50vh;
    overflow-y: scroll;
    background-color: rgb(245, 245, 245);
  }

    /deep/ .txt-filter {
    height: 50px;
    }
  .disable-click{
    pointer-events: none;
  }
</style>
