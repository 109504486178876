<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <layout-default-filters @open="$_onOpen" @apply="$_applyQueryString">
    <template #superior>
    <div class='div-geral'>
      <div class='primeiro-filtro'>
        <v-form ref="form">
          <core-date-picker-field clearable
            v-model="filters.createdFrom"
            label="De"
          />
          <core-date-picker-field clearable
            v-model="filters.createdBefore"
            label="Até"
            :rules="createdBeforeRules"
          />

          <v-select
            ref="filtroSituacao"
            outlined
            rounded
            :dense="!$_isMobile"
            label="Situação"
            :item-title="situacao.processo"
            :items="situacao" :append-icon="filters.situacao ? 'mdi-close' : 'mdi-chevron-down'"
            v-model="filters.situacao" item-text="processo" 
            :item-value="situacao.processo"
            @click:append="filters.situacao ? $_clearSituacao($event) : $_openSituacao()"
            >
          </v-select>
        </v-form>
      </div>

      <div class='terceiro-filtro'>
        <embedded-filter 
        ref="filtroA"
        @limpar="filters.pessoa = null"
        @subirform="$_subirForm($event, 1)"
        tabela="pessoa"
        :campos="['pesidpessoa', 'pesnome']"
        :label="['Código', 'Nome']">
          <template v-slot:card="{ item }">
              <v-card class='cardpessoa' @click="$_onClickCardPessoa(item.pesnome,
              item.pesidpessoa)">
                <b>{{item.pesidpessoa}} - {{item.pesnome}} </b><br/>
                <div v-if="item.pesnomefantasia">
                  {{item.pesnomefantasia}}
                </div>
                <div v-if="item.pescpf">
                  CPF: {{item.pescpf}}
                </div>
              </v-card>
          </template>
        </embedded-filter>
      </div>

    </div>
    </template>
  </layout-default-filters>
</template>

<script>
import LayoutDefaultFilters from '@/layouts/default/NewFilters.vue';
import CoreDatePickerField from '@/components/core/date-picker-field/Index.vue';
import EmbeddedFilter from '@/components/core/embedded-filter/Index.vue';
import { NFE_QUERIES } from '@/constants/QUERY_FILTERS';
import { getQueryValue, setQueryValue } from '@/services/query-string';


import moment from 'moment';
import { NFE_STORAGE } from '@/constants/STORAGE';
import notaatt from '@/utils/notaatt';


export default {
  name: 'ViewsHomeFilters',
  components: {
    LayoutDefaultFilters,
    CoreDatePickerField,
    EmbeddedFilter,
  },
  data(){
    return {
      isValid: false,

      filters: {
        pessoa: null,
        createdFrom: null,
        createdBefore: null,
        situacao: null
      },
      labels: {
        pessoa: null,
      },
      situacao: [
        {
          processo:'EMITIR',
        },
        {
          processo:'EM PROCESSAMENTO',
        },
        {
          processo:'EMITIDO',
        },
        {
          processo:'ERRO',
        },

      ],

      createdBeforeRules: [
        () => {
          const from = new Date(this.filters.createdFrom);
          const to = new Date(this.filters.createdBefore);

          return to >= from || 'Data Final deve ser superior a Data Inicial';
        },
      ],
    };
  },
  computed: {
    $_isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  methods: {
    $_openSituacao(){
      const activator = this.$refs.filtroSituacao.$el.querySelector('.v-input__slot');
      if (activator) {
        activator.click(); // Simulate a click to open the dropdown
      }
    },
    $_clearSituacao(e){
      if(e.target.className.includes('mdi-close')) {
        this.filters.situacao = ""
      }
    },
    $_applyQueryString() {
      setQueryValue(NFE_QUERIES.createdFrom, this.filters.createdFrom);
      setQueryValue(NFE_QUERIES.createdBefore, this.filters.createdBefore);
      setQueryValue(NFE_QUERIES.pessoa, this.filters.pessoa);
      setQueryValue(NFE_QUERIES.situacao,this.filters.situacao)

      sessionStorage.setItem(NFE_STORAGE.createdFrom, this.filters.createdFrom);
      sessionStorage.setItem(NFE_STORAGE.createdBefore, this.filters.createdBefore);
      if (this.filters.pessoa) {
        sessionStorage.setItem(NFE_STORAGE.filtropessoa, this.labels.pessoa);
      } else {
        sessionStorage.removeItem(NFE_STORAGE.filtropessoa);
      }
      if (this.filters.situacao) {
        sessionStorage.setItem(NFE_STORAGE.filtrosituacao, this.filters.situacao);
      } else {
        sessionStorage.removeItem(NFE_STORAGE.filtrosituacao);
      }
    },

    $_FilterReset() {
      this.filters.createdFrom = getQueryValue(
        NFE_QUERIES.createdFrom, this.filters.createdFrom,
      );
      this.filters.createdBefore = getQueryValue(
        NFE_QUERIES.createdBefore,
        this.filters.createdBefore,
      );
      this.filters.situacao = getQueryValue(
        NFE_QUERIES.situacao,
        this.filters.situacao,
      );
    },

    $_onOpen() {
      this.$refs.filtroA.$_subirFormInterno(false);
      notaatt()
    },

    $_subirForm(valor, filtro) {
      if (filtro === 1) {
          if (valor.bool) {
            if (valor.filtro) { return; }
            document.querySelector('.primeiro-filtro').style.opacity = '0';
            document.querySelector('.primeiro-filtro').style.zIndex = '-1';
            document.querySelector('.terceiro-filtro').style.top = '-300px';
            if(!this.$_isMobile){
              document.querySelector('.terceiro-filtro').style.top = '-260px';
            }
          } else {
            if (!valor.filtro) { return; }
            document.querySelector('.primeiro-filtro').style.opacity = '1';
            document.querySelector('.primeiro-filtro').style.zIndex = '2';
            document.querySelector('.terceiro-filtro').style.top = '-50px';
          }
      }
    },

    $_onClickCardPessoa(nome, codigo) {
      this.filters.pessoa = codigo;
      this.$refs.filtroA.$_setValue(`${codigo} - ${nome}`);
      this.labels.pessoa = `${codigo} - ${nome}`;
      this.$refs.filtroA.$_subirFormInterno(0);
    },
  },

  watch: {
    filters: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      },
    },

    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.filters.createdFrom = getQueryValue(
          NFE_QUERIES.createdFrom,
          this.filters.createdFrom,
        );
        this.filters.createdBefore = getQueryValue(
          NFE_QUERIES.createdBefore,
          this.filters.createdBefore,
        );
        this.filters.pessoa = getQueryValue(
          NFE_QUERIES.pessoa,
          this.filters.pessoa,
        );
        this.filters.situacao = getQueryValue(
          NFE_QUERIES.situacao,
          this.filters.situacao,
        );
      },
    },
  },

  created() {
      setQueryValue(NFE_QUERIES.createdFrom, moment().format('YYYY-MM-DD'));
      setQueryValue(NFE_QUERIES.createdBefore, moment().format('YYYY-MM-DD'));
      console.log('query', getQueryValue(NFE_QUERIES.pessoa))
  },
  mounted() {
      const pessoa = sessionStorage.getItem(NFE_STORAGE.filtropessoa) ?? '';
      this.$refs.filtroA.$_setValue(pessoa);
  }
};
</script>

<style scoped>

  .div-geral {
    height: 0%;
  }

  .primeiro-filtro {
    transition: opacity 0.3s;
    position: relative;
    opacity: 1;
    z-index: 1;
  }
  .segundo-filtro {
    transition: top 0.4s;
    position: relative;
    z-index: 1;
    height: 0px;
  }

  .terceiro-filtro {
    transition: top 0.4s;
    position: relative;
    z-index: 1;
    height: 0px;
  }

</style>
