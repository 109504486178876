<template>
  <div>
    <h1 class="label-sobre-borda">
      {{label}}
    </h1>
    <money
      v-model="value"
      v-bind="money"
      @blur.native="$_emitter('emitblur');"
      class="mytextfield"
      ref="textfield"
      style="text-align: right;"
      :maxlength="max"
      ma-0>
    </money>
  </div>
</template>

<script>

import { Money } from 'v-money';

export default {
  name: 'CoreNewTextField',

  components: {
    Money,
  },

  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    number: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
    },
    precision: {
      type: Number,
      default: 3,
    },
    valorStorage: null,
  },

  data() {
    return {
      value: this.valorStorage,
      money: {
        decimal: ',',
        thousands: '.',
        suffix: '',
        precision: this.precision,
        masked: false,
      },
    };
  },

  methods: {
    $_emitter(name) {
      this.$emit(name, this.value);
    },
  },

  computed: {
    $_isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

<style scoped>

  .mytextfield {
    background-color: white;
    border: 1px solid grey;
    height: 56px;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
  }

  .mytextfield:focus  {
    outline: 1.5px solid rgb(2, 202, 2);
    margin: none;
  }

  .label-sobre-borda {
    margin-top:-7px;
    margin-left:12px;
    background: linear-gradient(rgba(0,0,0,0) 30%, rgba(255,255,255,1) 30%);
    color: rgb(97, 97, 97);
    font-weight: 400;
    font-size: 12px;
    z-index: 1;
    position: absolute;
    display: inline-block;
  }
</style>
