<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <layout-default-filters @open="$_onOpen" @apply="$_applyQueryString">
    <template #superior>
    <div class='div-geral'>
      <div class='primeiro-filtro'>
        <v-form ref="form">
          <core-date-picker-field clearable
            v-model="filters.createdFrom"
            label="De"
          />
          <core-date-picker-field clearable
            v-model="filters.createdBefore"
            label="Até"
            :rules="createdBeforeRules"
          />
        </v-form>
      </div>
      <div class='segundo-filtro'>
        <embedded-filter 
        ref="filtroA"
        @limpar="filters.pessoa = null"
        @subirform="$_subirForm($event, 1)"
        tabela="pessoa"
        :campos="['pesidpessoa', 'pesnome']"
        :label="['Código', 'Nome']">
          <template v-slot:card="{ item }">
              <v-card class='cardpessoa' @click="$_onClickCardPessoa(item.pesnome,
              item.pesidpessoa)">
                <b>{{item.pesidpessoa}} - {{item.pesnome}} </b><br/>
                <div v-if="item.pesnomefantasia">
                  {{item.pesnomefantasia}}
                </div>
              </v-card>
          </template>
        </embedded-filter>
      </div>
      <div class='terceiro-filtro'>
        <embedded-filter 
        ref="filtroB"
        @limpar="filters.propriedade = null"
        @subirform="$_subirForm($event, 2)"
        tabela="propriedade"
        :campos="['proidpropriedade', 'pronome']"
        :label="['Código', 'Nome']">
          <template v-slot:card="{ item }">
              <v-card class='cardpropriedade' @click="$_onClickCardPropriedade(item.pronome,
              item.proidpropriedade)">
                <b>{{item.proidpropriedade}} - {{item.pronome}} </b><br/>
                <!-- <div v-if="item.pesnomefantasia">
                  {{item.pro}}
                </div> -->
              </v-card>
          </template>
        </embedded-filter>
      </div>
    </div>
    </template>
  </layout-default-filters>
</template>

<script>
import LayoutDefaultFilters from '@/layouts/default/NewFilters.vue';
import CoreDatePickerField from '@/components/core/date-picker-field/Index.vue';
import EmbeddedFilter from '@/components/core/embedded-filter/Index.vue'

import { LANCAMENTO_QUERIES } from '@/constants/QUERY_FILTERS';
import { getQueryValue, setQueryValue } from '@/services/query-string';


import { LANCAMENTO_STORAGE } from '@/constants/STORAGE';
import geraData from '@/utils/geradata';


export default {
  name: 'ViewsHomeFilters',
  components: {
    LayoutDefaultFilters,
    CoreDatePickerField,
    EmbeddedFilter,
  },
  data(){
    return {
      isValid: false,

      filters: {
        createdFrom: null,
        createdBefore: null,
        pessoa: null,
        propriedade: null,
      },
      labels: {
        pessoa: null,
        propriedade: null,
      },
      createdBeforeRules: [
        () => {
          const from = new Date(this.filters.createdFrom);
          const to = new Date(this.filters.createdBefore);

          return to >= from || 'Data Final deve ser superior a Data Inicial';
        },
      ],
    };
  },

  methods: {
    $_applyQueryString() {
      setQueryValue(LANCAMENTO_QUERIES.createdFrom, this.filters.createdFrom);
      setQueryValue(LANCAMENTO_QUERIES.createdBefore, this.filters.createdBefore);
      setQueryValue(LANCAMENTO_QUERIES.pessoa, this.filters.pessoa);
      setQueryValue(LANCAMENTO_QUERIES.propriedade, this.filters.propriedade);

      sessionStorage.setItem(LANCAMENTO_STORAGE.createdFrom, this.filters.createdFrom);
      sessionStorage.setItem(LANCAMENTO_STORAGE.createdBefore, this.filters.createdBefore);
      if (this.filters.pessoa) {
        sessionStorage.setItem(LANCAMENTO_STORAGE.filtropessoa, this.labels.pessoa);
      } else {
        sessionStorage.removeItem(LANCAMENTO_STORAGE.filtropessoa);
      }
      if (this.filters.propriedade) {
        sessionStorage.setItem(LANCAMENTO_STORAGE.filtropropriedade, this.labels.propriedade);
      } else {
        sessionStorage.removeItem(LANCAMENTO_STORAGE.filtropropriedade);
      }
    },

    $_FilterReset() {
      this.filters.createdFrom = getQueryValue(
        LANCAMENTO_QUERIES.createdFrom, this.filters.createdFrom,
      );
      this.filters.createdBefore = getQueryValue(
        LANCAMENTO_QUERIES.createdBefore,
        this.filters.createdBefore,
      );
      this.filters.createdBefore = getQueryValue(
        LANCAMENTO_QUERIES.pessoa,
        this.filters.pessoa,
      );
      this.filters.createdBefore = getQueryValue(
        LANCAMENTO_QUERIES.propriedade,
        this.filters.createdBefore,
      );
    },

    $_onOpen() {
      this.$refs.filtroA.$_subirFormInterno(false);
      this.$refs.filtroB.$_subirFormInterno(false);
    },

    $_subirForm(valor, filtro) {
      if (filtro === 1) {
        console.log(valor, 'valor');
        if (valor.bool) {
          if (valor.filtro) { return; }
          document.querySelector('.primeiro-filtro').style.opacity = '0';
          document.querySelector('.primeiro-filtro').style.zIndex = '-1';
          document.querySelector('.terceiro-filtro').style.opacity = '0';
          document.querySelector('.terceiro-filtro').style.zIndex = '-1';
          document.querySelector('.segundo-filtro').style.top = '-210px';
        } else {
          if (!valor.filtro) { return; }
          document.querySelector('.primeiro-filtro').style.opacity = '1';
          document.querySelector('.primeiro-filtro').style.zIndex = '2';
          document.querySelector('.terceiro-filtro').style.opacity = '1';
          document.querySelector('.terceiro-filtro').style.zIndex = '1';
          document.querySelector('.segundo-filtro').style.top = '-49px';
        }
      } else {
          if (valor.bool) {
            if (valor.filtro) { return; }
            document.querySelector('.primeiro-filtro').style.opacity = '0';
            document.querySelector('.primeiro-filtro').style.zIndex = '-1';
            document.querySelector('.segundo-filtro').style.opacity = '0';
            document.querySelector('.segundo-filtro').style.zIndex = '-1';
            document.querySelector('.terceiro-filtro').style.top = '-220px';
          } else {
            if (!valor.filtro) { return; }
            document.querySelector('.primeiro-filtro').style.opacity = '1';
            document.querySelector('.primeiro-filtro').style.zIndex = '2';
            document.querySelector('.segundo-filtro').style.opacity = '1';
            document.querySelector('.segundo-filtro').style.zIndex = '1';
            document.querySelector('.terceiro-filtro').style.top = '50px';
          }
      }
    },

    $_onClickCardPessoa(nome, codigo) {
      this.filters.pessoa = codigo;
      this.$refs.filtroA.$_setValue(`${codigo} - ${nome}`);
      this.labels.pessoa = `${codigo} - ${nome}`;
      this.$refs.filtroA.$_subirFormInterno(0);
    },

    $_onClickCardPropriedade(nome, codigo) {
      this.filters.propriedade = codigo;
      this.$refs.filtroB.$_setValue(`${codigo} - ${nome}`);
      this.labels.propriedade = `${codigo} - ${nome}`;
      this.$refs.filtroB.$_subirFormInterno(0);
    }
  },

  watch: {
    filters: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      },
    },

    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.filters.createdFrom = getQueryValue(
          LANCAMENTO_QUERIES.createdFrom,
          this.filters.createdFrom,
        );

        this.filters.createdBefore = getQueryValue(
          LANCAMENTO_QUERIES.createdBefore,
          this.filters.createdBefore,
        );

        this.filters.pessoa = getQueryValue(
          LANCAMENTO_QUERIES.pessoa,
          this.filters.pessoa,
        );

        this.filters.propriedade = getQueryValue(
          LANCAMENTO_QUERIES.propriedade,
          this.filters.propriedade,
        );
      },
    },
  },

  created() {
      setQueryValue(LANCAMENTO_QUERIES.createdFrom, sessionStorage.getItem(LANCAMENTO_STORAGE.createdFrom) ?? geraData(new Date(), 'YYYY-MM-DD'));
      setQueryValue(LANCAMENTO_QUERIES.createdBefore, sessionStorage.getItem(LANCAMENTO_STORAGE.createdBefore) ?? geraData(new Date(), 'YYYY-MM-DD'));
  },

  mounted() {
      const pessoa = sessionStorage.getItem(LANCAMENTO_STORAGE.filtropessoa) ?? '';
      const propriedade = sessionStorage.getItem(LANCAMENTO_STORAGE.filtropropriedade) ?? '';
      this.$refs.filtroA.$_setValue(pessoa);
      this.$refs.filtroB.$_setValue(propriedade);
  }
};
</script>

<style scoped>

  .div-geral {
    height: 0%;
  }

  .primeiro-filtro {
    transition: opacity 0.3s;
    position: relative;
    opacity: 1;
    z-index: 1;
  }

  .segundo-filtro {
    transition: top 0.4s;
    position: relative;
    z-index: 1;
    height: 0px;
  }

  .terceiro-filtro {
    transition: top 0.4s;
    position: relative;
    z-index: 1;
    height: 0px;
  }


</style>
