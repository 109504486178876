<template>
  <core-view>
    <div id="app">
      <camera />
    </div>
  </core-view>
</template>

<script>
import Camera from '@/views/camera/VisitaCamera.vue';
import CoreView from '@/components/core/view/Index.vue';


export default {
  components: {
    CoreView,
    Camera,
  },
};
</script>

<style>

</style>
