<template>
  <app-card-operador-skeleton v-bind="$attrs" v-on="$listeners" :actions="actions"
  @click="$_selecionaOperador(value)">
    <template #contrato >
      <div class="superior">
        <div>
          {{ value.pesidpessoa }}
        </div>
       <div v-if="value.pesnome">
        - {{ value.pesnome }}
       </div>
      </div>
     </template>
    <template #fantasia>
      <div v-if="value.pesnomefantasia && value.pesnomefantasia !== ''">
      {{ value.pesnomefantasia }}
      </div>
    </template>
    <template #detalhe1>
      <div v-if="value.pescpf">CPF: {{value.pescpf}}</div>
    </template>

    <slot />
  </app-card-operador-skeleton>
</template>

<script>
import { ABASTECIMENTO_STORAGE, OPERADOR_STORAGE, ROTA_STORAGE } from '@/constants/STORAGE';
import { ROUTE_AP_CADASTRO, ROUTE_CADASTRO, ROUTE_VISITA_CADASTRO } from '@/constants/ROUTES';
// import moment from 'moment-timezone';
import AppCardOperadorSkeleton from './Skeleton.vue';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: 'AppCardOperador',

  components: {
    AppCardOperadorSkeleton,
  },
  data(){
    return {
      rota: null,
      rotaAnterior: null,
    }
  },

  methods: {
    // FormataData(date) {
    //   // return moment(date, 'YYYY-MM-DD').format('DD/MM-HH:MM');
    //   return moment.tz(date, 'America/Sao_Paulo').local().format('DD/MM-HH:mm');
    // },
    $_redirect() {
      this.rotaAnterior = sessionStorage.getItem(ROTA_STORAGE.anterior)
      console.log('rotatn', this.rotaAnterior)
      switch(this.rotaAnterior) {
        case 'cadastro':
          this.rota = ROUTE_CADASTRO;
          break;
        case 'apcadastro':
          this.rota = ROUTE_AP_CADASTRO
          break;
        case 'visitacadastro':
          this.rota = ROUTE_VISITA_CADASTRO
          break;
      }
    },

    async $_selecionaOperador(operador) {
      const tipo = getSessionStorage(OPERADOR_STORAGE.tipo);
      if (Number(tipo) === 1) {
        sessionStorage.setItem(ABASTECIMENTO_STORAGE.operadora, JSON.stringify(operador));
      } else {
        sessionStorage.setItem(ABASTECIMENTO_STORAGE.operadorb, JSON.stringify(operador));
      }

      this.$router.replace({
        ...this.$route,
        name: this.rota.name,
      });
    },
  },
  created(){
    this.$_redirect();
  },

  props: {
    value: Object,
    actions: Boolean,
  },
};
</script>
/<style scoped>
#principal{
  display: block;
}

.flex-container {
  display: flex;
}

.flex-container > * {
  margin-right: 3px;
}

.superior {
  display: flex;
}
</style>
