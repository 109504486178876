<template>
  <layout-default-dialog-appbar :backTo="rotanfe" withDialog>
    Cadastro NFe
  </layout-default-dialog-appbar>
</template>

<script>
import LayoutDefaultDialogAppbar from '@/layouts/default/DialogAppBar.vue';
import { ROUTE_NFE } from '@/constants/ROUTES';

export default {
  name: 'ViewAppBarNFe',

  components: {
    LayoutDefaultDialogAppbar,
  },

  data() {
    return {
      rotanfe: ROUTE_NFE
    };
  },
};
</script>
