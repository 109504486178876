<template>
  <div class="wrapper">
    <video
      class="video"
      :class="facingMode === 'environment' ? 'user' : ''"
      ref="video"
      id="blinkVideo"
    />
    <canvas style="display: none" ref="canva" />
<!--
    <button
      class="button is-rounded is-outlined switch-button"
      @click="switchCamera"
      :disabled="switchingCamera"
    >
      <b-icon pack="fas" icon="sync-alt" />
    </button> -->
    <div class="photo-button-container">
      <button v-if='delay' class="button photo-button" @click="TakePhoto();">
        <v-icon>mdi-camera</v-icon>
      </button>
      <button type="file" v-if='delay' class="button file-button" @click="$_sendFile();">
        <v-icon>mdi-image</v-icon>
      </button>
      <!-- <button v-if='delay' class="button flash-button" @click="Flash();"
      id="flash"
      style='background: rgb(0,0,0,1);'>
        <v-icon v-if='flash'>mdi-white-balance-sunny</v-icon>
        <v-icon v-else style="color: white">mdi-weather-sunny</v-icon>
      </button> -->
    </div>
    <photos-gallery class="gallery" :photos="photos" />
    <input v-show="!iOS" type="range" orient="vertical" class="camera-slider">
    <v-snackbar
        v-model="errorPopUp"
        top
      >
        ERRO: {{ errorLog }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="red"
            v-bind="attrs"
            @click="errorPopUp = false"
          >
            OK
          </v-btn>
        </template>
      </v-snackbar>
      <input type="file" multiple class="inputEl" @change="$_salvaArquivoGaleria()"
      accept=".png,.jpg,.jpeg" id="inputEl">
      </div>
</template>

<script>
import PhotosGallery from '@/views/camera/PhotosGallery.vue';
import errorHandler from '@/utils/error-handler';
// import axios from '@@/node_modules/axios';

export default {
  components: {
    PhotosGallery,
  },
  data() {
    return {
      photos: [],
      mediaStream: null,
      videoDevices: [],
      facingMode: 'environment',
      counter: 0,
      switchingCamera: false,
      // Data criada por mim está abaixo.
      imageURL: null,
      errorPopUp: false,
      errorLog: undefined,
      file: '',
      flash: false,
      // delay é equivalente a invisible, block é equivalente a enabled.
      delay: true,
      cameraDelay: 0,
      iOS: false,
      fileLoaderIndex: 0,
      reader: new FileReader(),
    };
  },
  methods: {

    handleFileUpload() {
      // eslint-disable-next-line prefer-destructuring
      this.file = this.$refs.file.files[0];
      console.log(this.file);
    },

    async StartRecording(facingMode) {
      try {
        this.facingMode = facingMode;
        const { video } = this.$refs;
        video.setAttribute('playsinline', true);
        video.setAttribute('autoplay', '');
        video.setAttribute('muted', '');

        if (this.iOS) {
          this.mediaStream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode },
          });
          video.srcObject = this.mediaStream;
        } else {
          this.mediaStream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode, zoom: true },
          });
          video.srcObject = this.mediaStream;
          const [track] = this.mediaStream.getVideoTracks();
          const capabilities = track.getCapabilities();
          const input = document.querySelector('input[type="range"]');
          const settings = track.getSettings();
          input.min = capabilities.zoom.min;
          input.max = capabilities.zoom.max;
          input.step = capabilities.zoom.step;
          input.value = settings.zoom;
          video.srcObject = this.mediaStream;
          input.oninput = ((event) => {
            track.applyConstraints({ advanced: [{ zoom: event.target.value }] });
          });
          input.hidden = false;
        }

        // eslint-disable-next-line no-return-await
        return await video.play();
      } catch {
        if (this.iOS) {
          errorHandler('Dispositivo de mídia inválido ou inexistente (iOS)');
        } else {
          errorHandler('Dispositivo de mídia inválido ou inexistente');
        }
      }
      return null;
    },

    async TakePhoto() {
      // const [track] = this.mediaStream.getVideoTracks();
      if (this.flash) {
        // await track.applyConstraints({ advanced: [{ torch: true }] });
        // this.cameraDelay = 950;
      }
      const el = document.getElementById('blinkVideo');
      setTimeout(() => {
        this.Blink(el, 225);
        const { video } = this.$refs;
        const { canva } = this.$refs;
        const width = video.videoWidth;
        const height = video.videoHeight;
        canva.width = width;
        canva.height = height;
        const ctx = canva.getContext('2d');
        ctx.save();
        // Setor responsavel pela inversão da camera.
        if (this.facingMode === 'user') {
          ctx.scale(-1, 1);
          ctx.drawImage(video, width * -1, 0, width, height);
        } else {
          ctx.drawImage(video, 0, 0);
        }
        // track.applyConstraints({ advanced: [{ torch: false }] });

        ctx.restore();

        const testURL = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAJCAIAAACAMfp5AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAASSURBVBhXY3gro4IVDQ8JGRUAb2hUqfhUtvQAAAAASUVORK5CYII=';
        console.log(testURL);
        this.imageURL = canva.toDataURL('image/png');
        // this.imageURL = testURL;

        // [this.file] = [this.$refs.file.files];
        this.photos.push({
          id: this.counter += 1,
          src: (this.imageURL),
        });
      }, this.cameraDelay);
      this.cameraDelay = 0;
    },

    async switchCamera() {
      this.switchingCamera = true;
      const tracks = this.mediaStream.getVideoTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      await this.StartRecording(
        this.facingMode === 'environment' ? 'user' : 'environment',
      );
      this.switchingCamera = false;
    },

    async Blink(el, duration) {
      const element = el;
      element.style.opacity = 0;
      setTimeout(() => { element.style.opacity = 1; }, duration);
    },

    resetSlider() {
      const input = document.querySelector('input[type="range"]');
      const [track] = this.mediaStream.getVideoTracks();
      const capabilities = track.getCapabilities();
      input.value = capabilities.zoom.min;
      track.applyConstraints({ advanced: [{ zoom: input.value }] });
    },

    Flash() {
      this.flash = !this.flash;
      if (!this.flash) {
        document.getElementById('flash').style.background = 'rgb(0,0,0,0.4)';
      } else {
        document.getElementById('flash').style.background = 'rgb(255,255,255,1)';
      }
    },

    getOS() {
      if (navigator.appVersion.indexOf('like Mac') > -1) {
        this.iOS = true;
      }
    },

    async $_sendFile() {
      document.getElementById('inputEl').value = null;
      document.getElementById('inputEl').click();
    },

    async $_salvaArquivoGaleria() {
      try {
        if (this.fileLoaderIndex === document.getElementById('inputEl').files.length) {
          this.fileLoaderIndex = 0;
          return;
        }
        this.reader.readAsDataURL(document.getElementById('inputEl').files[this.fileLoaderIndex]);
        this.reader.onloadend = () => {
          this.photos.push({
            id: this.counter += 1,
            src: this.reader.result,
          });
          console.log(this.photos);
          this.fileLoaderIndex += 1;
          this.$_salvaArquivoGaleria();
        };
      } catch (err) {
        console.log(err);
      }
    },
  },

  async mounted() {
    let devices;
    try {
      devices = await navigator.mediaDevices.enumerateDevices();
    } catch (err) {
      errorHandler('Não existem dispositivos compativeis');
      console.log(err);
      return;
    }
    this.videoDevices = devices.filter((d) => d.kind === 'videoinput');
    await this.StartRecording(
      'environment',
    );
    // this.videoDevices.length === 1 ? 'user' : 'environment', IA DENTRO DO STARTRECORDING
    if (this.iOS && this.facingMode === 'user') {
      this.switchCamera();
    }
    try {
      if (!this.iOS) {
        this.resetSlider();
      }
    } catch {
      if (this.iOS) {
        errorHandler('Erro ao ativar a ferramenta de zoom (iOS)');
      } else {
        errorHandler('Erro ao ativar a ferramenta de zoom');
      }
    }

    this.Flash();
  },

  beforeMount() {
    this.getOS();
  },
};
</script>

<style scoped>

.video.front {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.wrapper {
  background-color: black;
  display: grid;
  left: 0px;
  top:  0px;
  width: 100vw;
  height: 100vh;
  grid-template-columns: [left] 90vw [bs] 5vw [es] 5vw [right];
  grid-template-rows: [top] 5vh [bs] 5vh [es] 60vh [middle] 10vh [bottom] 20vh [end];
  justify-items: center;
  overflow: hidden;
  position: absolute;
}

.video {
  height: 100%;
  width: 100%;
  grid-column: left/right;
  grid-row: top / bottom;
  user-select: none;
  max-width: 100%;
  max-height: 76%;
  position: fixed;
  left: 0%;
  overflow:hidden;
}

.switch-button {
  grid-column: bs / es;
  grid-row: bs / es;
  z-index: 5;
  border-radius: 100%;
  position: fixed;
  right: 7vh;
  top: 10vh;
  width: 6vh;
  height: 6vh;
  font-size: 2vh;
}

.photo-button-container {
  grid-column: left / right;
  grid-row: middle / bottom;
  z-index: 5;
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: center;
}

.photo-button {
  width: 8vh;
  height: 8vh;
  border-radius: 100%;
  position: fixed;
  bottom: 16vh;
  background: rgb(255,255,255,0.4);
}

.photo-button {
  font-size: 4vh;
  color: rgb(0, 0, 0);
}

.file-button {
  width: 7vh;
  height: 7vh;
  border-radius: 100%;
  right: 4vw;
  position: fixed;
  bottom: 16vh;
  background: rgb(255,255,255,0.4);
}

.file-button {
  font-size: 3vh;
  color: rgb(0, 0, 0);
}

.flash-button{
  width: 6.8vh;
  height: 6.8vh;
  border-radius: 100%;
  position: fixed;
  bottom: 26vh;
  right: 6vw;
  background: rgb(255,255,255,0.4);
}

.gallery {
  grid-column: left / right;
  grid-row: bottom / end;
  z-index: 10;
  position: fixed;
  height: 15%;
  bottom: 0;
}

.camera-slider {
  -webkit-appearance: slider-vertical;
  position: fixed;
  top: 35%;
  right: -8vw;
}

.inputEl {
  display: none;
}

</style>
