<template>
  <v-card class="wrapper-formulario">
    <v-form ref="form" class="form">
      <slot name="form">
      </slot>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: 'CoreForm',

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style scoped>

  .form {
    width: 100%;
  }

  .wrapper-formulario {
    display: flex;
    justify-content: center;
    padding: 15px 15px 25px 15px;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 700px) {
    .wrapper-formulario {
      width: 100%;
    }
  }

</style>
