<template>
  <layout-default-app-bar :back-to="rota">
    Visita Técnica - ID: {{id}}
  </layout-default-app-bar>
</template>
<script>
import LayoutDefaultAppBar from '@/layouts/default/AppBar.vue'
import {ROUTE_VISITA} from '@/constants/ROUTES'
import { VISITA_STORAGE } from '@/constants/STORAGE';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}
export default {
  name: 'ViewVisitaInfoAppBar',
  components: {
    LayoutDefaultAppBar,
  },
  data(){
    return{
      rota: ROUTE_VISITA,
      id: null,
    }
  },
  created(){
    this.id = parseInt(getSessionStorage(VISITA_STORAGE.idvisita))
  }
}
</script>