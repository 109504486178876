import db from "@/plugins/dexie";
import { WsEnviaDados } from "../../websocket";

export default {
    enviaNotaFiscal: () => {
        const notafiscal = db.notafiscal.where('nofidnuvem').equals('null').toArray();

        notafiscal.then(async (tabela) => {
            const notafiscalEmissao = await db.notafiscal
            .where({ nofnronota: 'null'})
            .and((item) => item.nofdataemissao !== undefined)
            .toArray();
            const notas = [...tabela, ...notafiscalEmissao]
            console.log('merge', notas)
            if (notas.length !== 0) {
                await WsEnviaDados({tipo: 'postNotaFiscal', msg: notas})
            }
        });
    },
    retornoNotaFiscal: ({ data }) => {
        const item = data.body;
        // query pela DataCriacao para fazer um handshake entre a WS para atribuir o idnuvem no lancamento
        const queryPromise = db.notafiscal.where({ nofdataatualizacao: String(item.nofdataatualizacao) }).toArray();
        queryPromise.then((tabela) => {
        tabela.forEach((linha) => {
            db.notafiscal.update(linha.id, {nofidnuvem: item.id, nofidusuario: item.nofidusuario})
            })
        })
    },
}