<template>
  <v-card rounded="md" v-bind="$attrs" v-on="$listeners">
    <v-card-title id= 'title'>
      <slot name="contrato">
        <v-skeleton-loader width="100%" class="pt-1" type="heading" />
      </slot>
    </v-card-title>
    <v-card-text >
      <slot  slot name="detalhe1">
        <v-skeleton-loader width="100%" class="pt-1" type="text" />
      </slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AppCardApontamentoSkeleton',

  props: {
    actions: Boolean,
  },
};
</script>

<style  scoped>
#title {
font-size: 14px;
font-weight: bold;
margin-bottom : 3px;
padding: 12px 16px 0px;
display: block;
}

</style>
