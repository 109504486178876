import Vue from 'vue'
import VueTheMask from 'vue-the-mask';
import App from './App.vue'

import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'

import './registerServiceWorker'

import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { setupBaseURL, setupApiOnResponseError } from './services/api-recebimentoweb';
import { SetupWebSocket, WSDefaultRoute } from './utils/ws/websocket'
import { ROUTE_SIGN_IN } from './constants/ROUTES';
import { SetupIsOnline } from './utils/isonline';
// import { ROUTE_SIGN_IN } from './constants/ROUTES';

setupBaseURL(); // URL Padrão API
WSDefaultRoute(); // URL Padrão WS
SetupIsOnline(); // Eventos da Página Offline/Online

// Reinicialização WS caso a página seja recarregada 
if(sessionStorage.getItem('auth:tokenlocal', null)) {
  SetupWebSocket(); 
}

Vue.config.productionTip = false;

Vue.use(VueTheMask);

Vue.component('v-text-field', VTextField) 
Vue.use(VCurrencyField, { 
	locale: 'pt-BR',
  allowNegative: false
})

Promise.all([
  setupBaseURL(),
  setupApiOnResponseError(async (err) => {
    if (err.response?.status === 401 && store.getters['auth/accessToken']) {
      await store.dispatch('auth/unauthenticate');

      if (router.currentRoute.path === ROUTE_SIGN_IN.path) return;

      router.push({
        path: ROUTE_SIGN_IN.name,
        query: { origin: router.currentRoute.fullPath },
      });
    }

    throw err;
  }),

])
  .then(() => {
    store.dispatch('$_setup');
  })
  .then(() => {
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  });

// Promise.all([
//   setupBaseURL(),
//   setupApiOnResponseError(async (err) => {
//     if (err.response?.status === 401 && store.getters['auth/accessToken']) {
//       await store.dispatch('auth/unauthenticate');

//       if (router.currentRoute.path === '/') return;

//       router.push({
//         path: 'signin',
//         query: { origin: router.currentRoute.fullPath },
//       });
//     }

//     throw err;
//   }),

// ])
//   .then(() => {
//     store.dispatch('$_setup');
//   })
//   .then(() => {
//     new Vue({
//       router,
//       store,
//       vuetify,
//       render: h => h(App)
//     }).$mount('#app')
//   });

