<template>
  <layout-default-appbar filterable>
    Visita Técnica
  </layout-default-appbar>
</template>
<script>
import LayoutDefaultAppbar from '@/layouts/default/AppBar.vue'
import { VISITA_INFO_STORAGE } from '@/constants/STORAGE'

export default {
  name: 'ViewVisitaAppbar',
  components: {
    LayoutDefaultAppbar,
  },
  created(){
    ['observacao','titulo','idinfo'].forEach((item) => {
      sessionStorage.removeItem(VISITA_INFO_STORAGE[item]);
    });
  }
}
</script>