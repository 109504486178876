<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :dense="!$_isMobile || dense"
    filled
    :items="dados"
    :item-text="itemLabel"
    :outlined="outlined"
    :append-icon="(readonly) ? '' : icon"
    :readonly="readonly"
    @click:append="$_trataClique"
    :background-color="$$_bgColor"
    class="mb-5"
    :return-object="returnObject"
    hide-details='auto'
    ref="focusinput"
  >
    <template v-slot:item="{ item }" v-if="custom">
      <div>
        <!-- Customize the item display however you want -->
        <span>{{ item.atacodigo }} - {{ item.atadescricao }}</span>
      </div>
    </template>
  </v-autocomplete>
</template>
<script>

import { OPERADOR_STORAGE } from '@/constants/STORAGE';

export default {
  name: 'CoreBtnAutoComplete',

  props: {
    icon: {
      type: String,
      default: 'mdi-dots-horizontal-circle-outline',
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    redirect: {
      type: Object,
      default: null,
    },
    modal: {
      type: Boolean,
      default: null,
    },
    dados: {
      type: Array,
      default: (() => ([])),
    },
    itemLabel: {
      type: String,
      default: '',
    },
    temvazio: {
      type: Boolean,
      default: false,
    },
    paramappend: {
      default: null,
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
    },
    custom: {
      type: Boolean,
    }
  },

  data() {
    return {
      txtInput: '',
      select: null,
    };
  },

  methods: {
    $_trataClique() {
      sessionStorage.setItem(OPERADOR_STORAGE.tipo, this.$props.paramappend);
      if (this.$props.redirect) {
        this.$router.replace({
          ...this.$route,
          name: this.$props.redirect.name,
        });
      } else {
        this.$refs.focusinput.focus();
      }
    },

    $_validaFrota() {
      this.$emit('frotaAlterada', this.txtInput);
    },
  },

  beforeMount() {
    console.log(this.$props.dados);
    this.select = this.$props.dados;
    if (this.$props.temvazio) {
      this.select.push({ [this.$props.itemLabel]: 'vazio' });
      console.log(this.select, 'uaaa');
    }
  },

  computed: {
    $_isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    $$_bgColor() {
      return (this.$props.readonly) ? '#ffd' : 'white';
    }
  },
};
</script>
