<template>
  <layout-default-dialog-appbar :back-to="rotaapontamento" :withDialog="!idatividade">
    {{ (idatividade) ? '' : 'Cadastrar' }} Atividade {{idatividade}}
  </layout-default-dialog-appbar>
</template>

<script>
import LayoutDefaultDialogAppbar from '@/layouts/default/DialogAppBar.vue';

import { ROUTE_APONTAMENTO } from '@/constants/ROUTES';
import { LANCAMENTO_STORAGE } from '@/constants/STORAGE';

export default {
  name: 'ViewFrotaAppBar',

  components: {
    LayoutDefaultDialogAppbar,
  },

  data() {
    return {
      rotaapontamento: ROUTE_APONTAMENTO,
      idatividade: (sessionStorage.getItem(LANCAMENTO_STORAGE.idlancamento)),
    };
  },
};
</script>
