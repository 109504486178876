<template>
  <core-view class="flex-column maxwidth" fill-height v-if="!loading">
    <v-btn class="divnovo" height="45" color="primary" @click="$_cadastroNFE" >{{labelBotao}}</v-btn>
    <div class="div"  :key="item.id" v-for="item in notas" ref="lista">
        <card-nfe :dados="item"/>
    </div>

    <div class="semnfe" v-if="notas.length === 0">
      <v-icon x-large>
        $vuetify.icons.NFe
      </v-icon>
      <div>
        Não existe nenhuma NF-e no período escolhido.
      </div>
    </div>
  </core-view>
</template>

<script>
// import errorHandler from '@/utils/error-handler';

import CoreView from '@/components/core/view/Index.vue';
import db from '@/plugins/dexie';
import moment from 'moment';
import { NFE_STORAGE, ROTA_STORAGE } from '@/constants/STORAGE';
import { NFE_QUERIES } from '@/constants/QUERY_FILTERS';
import { getQueryValue, setQueryValue} from '@/services/query-string';
import deepEquals from '@/utils/deep-equals';
import CardNfe from '@/components/app/card/nfe/Index.vue';
import { ROUTE_CADASTRO_NFE } from '@/constants/ROUTES';


function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: 'ViewNFe',

  components: {
    CoreView,
    CardNfe,
  },

  data() {
    return {
      notas: [],
      reload: 0,
      labelBotao: {
        type: String
      },
      loading: true,
      filters: {
        createdFrom: getSessionStorage(NFE_STORAGE.createdFrom, null),
        createdBefore: getSessionStorage(NFE_STORAGE.createdBefore, null),
        pessoa: null,
        situacao: null,
      },
    };
  },

  methods: {
    async $_cadastroNFE() {
      sessionStorage.setItem(ROTA_STORAGE.cadastro, 'nfe');
      this.$router.replace({
        ...this.$route,
        name: ROUTE_CADASTRO_NFE.name
      });
    },
    
    async $_load() {
      try {
        this.notas = await db.notafiscal.where('nofdataatualizacao').between(
          new Date(this.filters.createdFrom).toISOString(), new Date(`${this.filters.createdBefore}T23:59:00`).toISOString()
        ).reverse().toArray();
        if (this.filters.pessoa) {
          this.notas = await db.notafiscal.where('nofdataatualizacao').between(
          new Date(this.filters.createdFrom).toISOString(), new Date(`${this.filters.createdBefore}T23:59:00`).toISOString()
        ).and(item => item.nofidpessoa == this.filters.pessoa).reverse().toArray();
        }
        if (this.filters.situacao) {      
          this.notas = await db.notafiscal.where('nofdataatualizacao').between(
          new Date(this.filters.createdFrom).toISOString(), new Date(`${this.filters.createdBefore}T23:59:00`).toISOString()
        ).and(item => item.nofprocessamento === this.filters.situacao).reverse().toArray();
        }
        if (this.filters.situacao && this.filters.pessoa) {      
          this.notas = await db.notafiscal.where('nofdataatualizacao').between(
          new Date(this.filters.createdFrom).toISOString(), new Date(`${this.filters.createdBefore}T23:59:00`).toISOString()
        ).and(item => item.nofprocessamento === this.filters.situacao && item.nofidpessoa == this.filters.pessoa).reverse().toArray();
        }

      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    // SE ESTIVER COM PROBLEMAS DE PERFOMANCE, PROVAVELMENTE É A UTILIZAÇÃO DE HOOK DO DEXIE
     $_dbHook(){
      db.notafiscal.hook('updating', (update, notaID) => {
            const notaIndex = this.notas.findIndex((nota) => nota.id === notaID);
            if (notaIndex > -1){
              for (const [index, value] of Object.entries(update)) {
                this.notas[notaIndex][index] = value
              }
            }
      });
      },

    /* $_refresh() {
      this.loading = true;
      this.$_load();
    }, */

    $_FormataData(date) {
      // return moment(date, 'YYYY-MM-DD').format('DD/MM-HH:MM');
      return moment.tz(date, 'America/Sao_Paulo').local().format('DD/MM/YY-HH:mm');
    },
  },
    watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {

        const filters = {
          createdFrom: getQueryValue(NFE_QUERIES.createdFrom, this.filters.createdFrom),
          createdBefore: getQueryValue(
            NFE_QUERIES.createdBefore,
            this.filters.createdBefore,
          ),
          pessoa: getQueryValue(
            NFE_QUERIES.pessoa,
            null,
          ),
          situacao: getQueryValue(NFE_QUERIES.situacao, null),
        };

        if (!deepEquals(this.filters, filters)) {
          this.filters.createdFrom = filters.createdFrom;
          this.filters.createdBefore = filters.createdBefore;
          this.filters.pessoa = filters.pessoa;
          this.filters.situacao = filters.situacao
          
          this.$_load();
        }
      },
    },

    options: {
      deep: true,
      immediate:true,
      handler() {
        setQueryValue(NFE_QUERIES.createdFrom, this.filters.createdFrom);
        setQueryValue(NFE_QUERIES.createdBefore, this.filters.createdBefore);
        setQueryValue(NFE_QUERIES.pessoa, this.filters.pessoa);
        setQueryValue(NFE_QUERIES.situacao, this.filters.situacao);
      },
    },
  },

  created() {
    this.$_load();
    this.$_dbHook();
    this.labelBotao = (sessionStorage.getItem(NFE_STORAGE.formulario)) ? 'Retomar' : 'Novo';
  },
};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }
  .div{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .divnovo {
    text-align: center;
    margin-top: 20px;
  }
  .semnfe {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 125px;
    padding: 25px;
  }
  .semnfe > div {
    margin-top: 20px;
    width: 200px;
  }
  .maxwidth{
    max-width: 700px;
  }
</style>
