<template>
  <layout-default-appbar :backTo="rota">
    {{$_titulo}}
  </layout-default-appbar>
</template>
<script>
import LayoutDefaultAppbar from '@/layouts/default/AppBar.vue'
import { ROUTE_VISITA_INFO } from '@/constants/ROUTES'
import { VISITA_INFO_STORAGE } from '@/constants/STORAGE';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: 'ViewCameraAppbar',
  components: {
    LayoutDefaultAppbar,
  },
  computed: {
    $_titulo() {
      return this.titulo != null ? 'Alterar Imagem' : 'Capturar Imagem';
    },
  },
  data(){
    return {
      rota: ROUTE_VISITA_INFO,
      titulo: getSessionStorage(VISITA_INFO_STORAGE.idinfo, null)
    }
  }
}
</script>