import db from "@/plugins/dexie";
import { WsEnviaDados } from "../../websocket";

export default {
    // Qnd WS estabelece/reestabelece conexão com o tipo 'novoid
    // ela envia os abastecimento que foram feitos offline e estavam salvos no dexie
    enviaAbastecimento: () => {
        const abastecimentos = db.movimentacaoabastecimento.where('moaidnuvem').equals('null').toArray();

        abastecimentos.then((tabela) => {
            tabela.forEach((abb) => {
            console.log(abb, 'zzzzzzzzzzzzzzzzzzz');
            console.log("post", abb)
            WsEnviaDados({ tipo: 'postAbastecimento', msg: abb });
            });
        });
    },
    retornoAbastecimento: ({ data }) => {
        const item = data.body;

        const queryPromise = db.movimentacaoabastecimento.where('moadatacriacao').equals(String(item.moadatacriacao)).toArray();
        queryPromise.then((tabela) => {
        tabela.forEach((linha) => {
            db.movimentacaoabastecimento.update(linha.id, { moaidnuvem: item.id });
            });
        });
    },
}

