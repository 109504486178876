import connection from './core/connection';
import crud from './core/crud';
import geral from './core/geral';

import atividade from './tabelas/atividade';
import abastecimento from './tabelas/abastecimento.';
import notafiscal from './tabelas/notafiscal';
import visitatecnica from './tabelas/visitatecnica';

export default {
    ...connection,
    ...crud,
    ...geral,
    //
    ...atividade,
    ...abastecimento,
    ...notafiscal,
    ...visitatecnica,
    //
    novoid(data) {
        this.enviaLancamentoAtividade();
        this.enviaAbastecimento();
        this.enviaVisitaTecnica();
        this.enviaNotaFiscal();
        this.enviaDataAtt(data);
    }
}