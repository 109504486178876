<template>
  <layout-default-appbar :back-to="rota" filterable>
    Operador
  </layout-default-appbar>
</template>

<script>
import LayoutDefaultAppbar from '@/layouts/default/AppBar.vue';
import { ROTA_STORAGE } from '@/constants/STORAGE';

import { ROUTE_AP_CADASTRO, ROUTE_CADASTRO, ROUTE_VISITA_CADASTRO } from '@/constants/ROUTES';

export default {
  name: 'ViewOperadorAppBar',

  components: {
    LayoutDefaultAppbar,
  },

  data() {
    return {
      rota: null,
      rotaAnterior: null,
      rotaProxima: null,
    };
  },
  methods:{
    $_rota() {
      this.rotaAnterior = sessionStorage.getItem(ROTA_STORAGE.anterior)
      switch(this.rotaAnterior) {
        case 'cadastro':
          this.rota = ROUTE_CADASTRO;
          break;
        case 'apcadastro':
          this.rota = ROUTE_AP_CADASTRO
          break;
        case 'visitacadastro':
          this.rota = ROUTE_VISITA_CADASTRO
          break;
      }
    }
  },
  created() {
    this.$_rota();
  }
}

</script>
