<template>
  <v-dialog
  fullscreen
  v-bind="$attrs"
  v-on="$listeners"
  >
    <core-view class="flex-column" fill-height >
      <template>
        <core-form>
          <template #form>
            <div class='div-geral'>
              <div class='segundo-filtro'>
                <div class="titulo">
                  <h3 class="label" >
                      Selecionar {{label}}
                  </h3>
                </div>
                <br />
                <div class='radios opacity-switch'>
                  <v-radio-group v-model="radioGroup" row>
                    <v-radio
                      v-for="n in 2"
                      :key="n"
                      :label="radiolabel[n - 1]"
                      :value="n"
                    ></v-radio>
                  </v-radio-group>
                </div>
                <core-text-field clearable class='txt-filter'
                  @keydown.enter.prevent
                  v-model="conteudoinput"
                  :label='label'
                  :type='inputType'
                />
                <div class='btns-wrap'>
                  <v-btn rounded color='primary'
                  class='btns opacity-switch' @click='$_load(0)'>
                    <v-icon>mdi-arrow-left-thick</v-icon>
                  </v-btn>
                  <v-btn rounded color='primary' :class="{'disable-click': disableMiddle}" 
                  class='btns opacity-switch reducesize' @click='$_load(1)'>
                    <v-icon>mdi-arrow-left-thick</v-icon><v-icon>mdi-arrow-right-thick</v-icon>
                  </v-btn>
                  <v-btn rounded color='primary'
                  class='btns-mini opacity-switch' @click='$_load(2)'>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <template>
                <v-divider></v-divider>
                  <div
                    class="mx-auto cardlist"
                    max-width="500"
                  >
                    <v-list two-line class='scroll'>
                      <v-list-item-group
                        active-class="green--text"
                        multiple
                      >
                        <template v-for="(items, index) in items">
                          <v-list-item :key="items.id">
                            <v-card class='cardprincipal'
                            @click="$_onClickCard(items[itemlabel], items[itemvalue])">
                              {{items[itemvalue]}} - {{items[itemlabel]}}<br/>
                              {{items[itemdetail]}}
                            </v-card>
                          </v-list-item>
                          <v-divider
                            v-if="index < items.length - 1"
                            :key="index"
                          ></v-divider>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </div>
                  <v-divider></v-divider>
                </template>
              </div>
            </div>
          </template>
        </core-form>
      </template>
    </core-view>
  </v-dialog>
</template>

<script>

// import FormTextField from '@/components/form/components/text-field/Index.vue';
import errorHandler from '@/utils/error-handler';

import CoreView from '@/components/core/view/Index.vue';
import CoreTextField from '@/components/core/text-field/Index.vue';
import CoreForm from '@/components/core/formulario/Index.vue';

export default {
  name: 'FormSearchFilter',

  components: {
    CoreTextField,
    CoreForm,
    CoreView,
  },

  props: {
    label: {
      type: String,
    },
    itemvalue: {
      type: String,
    },
    itemlabel: {
      type: String,
    },
    load: {
      type: Array,
      default: (() => {}),
    },
    itemdetail: {
      type: String,
      default: '',
    },
    filterString: {
      type: Boolean,
      default: false,
    },
    radiolabel: {
      type: Array,
    },
  },

  data() {
    return {
      items: [],
      radioGroup: 2,
      conteudoinput: null,
      inputType: 'text',
      anterior: {
        parametroAnterior: null,
        nomeAnterior: null,
      },

      loading: false,
      disableMiddle: false,

      filters: {
        conteudoinputfinal: null,
      },

      options: {
        itemsPerPage: 10,
        sortBy: [this.$props.itemvalue],
        sortDesc: [true],
      },
    };
  },

  methods: {

    async $_load(parametroLoad) {
      this.loading = true;
      const ant = this.anterior;
      try {
        if ((typeof this.conteudoinput) === 'string') {
          this.filters.conteudoinputfinal = this.conteudoinput.toUpperCase();
        } else {
          this.filters.conteudoinputfinal = this.conteudoinput;
        }

        // Evitar requests repetidos.
        if (parametroLoad === ant.parametroAnterior && this.conteudoinput === ant.nomeAnterior) {
          return;
        }
        this.anterior.parametroAnterior = parametroLoad;
        this.anterior.nomeAnterior = this.conteudoinput;

        if (parametroLoad === 2) {
          this.conteudoinput = '';
          this.filters.conteudoinputfinal = 'null';
          this.$emit('retorno');
          return;
        }

        if (this.conteudoinput) {
          if (this.radioGroup === 2) {
            if (parametroLoad === 0) {
              this.items = this.$props.load.filter(
                (item) => item[this.$props.itemlabel].startsWith(this.filters.conteudoinputfinal)
              );
              console.log(this.items);
            } else {
              this.items = this.$props.load.filter(
                (item) => item[this.$props.itemlabel].includes(this.filters.conteudoinputfinal),
              );
              console.log(this.items);
            }
          } else {
            this.items = this.$props.load.filter(
              (item) => {
                if (this.filterString) {
                  if (item[this.$props.itemvalue] === this.filters.conteudoinputfinal)
                    return true;
                }
                if (item[this.$props.itemvalue] === parseInt(this.filters.conteudoinputfinal))
                  return true;
                },
            );
            console.log(this.items)
          }
        } else {
          this.items = null;
        }
        return;
      } catch (err) {
        errorHandler.call(this, err);
      } finally {
        this.loading = false;
      }
    },

    $_onClickCard(nome, codigo) {
      this.conteudoinput = `${nome}`;

      this.filters.codigo = codigo;

      this.$emit('retorno', codigo);
    },
  },

  watch: {
    radioGroup: {
      handler() {
        this.conteudoinput = '';
        if (this.radioGroup === 1) {
          this.inputType = 'tel';
          document.querySelector('.reducesize').style.opacity = '0.5';
          this.disableMiddle = true;
        } else {
          this.inputType = 'text';
          document.querySelector('.reducesize').style.opacity = '1';
          this.disableMiddle = false;
        }
        this.$_load();
      },
    },
  },
};
</script>

<style scoped>

  .btns-wrap {
    display: flex;
    justify-content: space-around;
    height: 50px;
  }

  .opacity-switch {
    transition: opacity 0.3s;
  }

  .btns {
    width: 33%;
    transition: opacity 0.3s;
  }

  .btns-mini {
    width: 10%;
    transition: opacity 0.3s;
  }

  .segundo-filtro {
    transition: top 0.4s;
    position: relative;
    z-index: 1;
    height: 0px;
  }

  .radios {
    transition: opacity 0.3s;
  }

  .mx-auto.cardlist {
    transition: opacity 0.3s;
    transition: top 0.4s;
    height: 50vh;
    overflow-y: scroll;
    background-color: rgb(245, 245, 245);
  }

  .cardprincipal {
    height: 80px;
    width: 100%;
    margin-top: 5px;
    padding: 5px;
  }

  /deep/ .txt-filter {
  height: 50px;
  }

  .titulo {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    border-radius: 20px 0% 20px 0%;
  }

  .label {
    display: flex;
    justify-content: center;
  }
  .disable-click {
    pointer-events: none;
  }
</style>
