import { WSModel } from "../../wsmodels";
import { prefixos } from "../../tabelas";
import db from "@/plugins/dexie";

export default {
    novoItem: ({ data }) => {
        const entity = WSModel[data.tabela](data.novo);
        console.log(entity);
        db[data.tabela].add(entity);
    },
    //
    atualizaItem: ({ data }) => {
        const entity = WSModel[data.tabela](data.novo);
        const campo = `${prefixos[data.tabela].prefixo}idnuvem`;
        const objWhere = { [campo]: data.novo.id };
        const itemUpdate = db[data.tabela].where(objWhere).first();
        itemUpdate.then((item) => {
            db[data.tabela].update(item.id, entity);
        });
    },
    //
    deletaItem: ({ data }) => {
        const adeletar = data.deletar;
        const exclusao = db[adeletar.loetabela].where(
        `${prefixos[adeletar.loetabela].prefixo}idnuvem`,
        ).equals(adeletar.loeidnuvem).delete();
        exclusao.then((deletou) => {
        if (deletou) {
            db.logexclusao.clear();
            db.logexclusao.add(WSModel.logexclusao(adeletar));
        }
        });
    },
    
}