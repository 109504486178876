import Vue from 'vue'
import VueRouter from 'vue-router'

import LayoutDefault from '@/layouts/default/Index.vue'

import { ROTA_STORAGE } from '@/constants/STORAGE'
import ApontamentoAppBar from '../views/apontamento/AppBar.vue'
import ApontamentoView from '../views/apontamento/Index.vue'
import ApontamentoFilter from '../views/apontamento/Filters.vue'
import ApCadastroAppBar from '../views/apcadastro/AppBar.vue'
import ApCadastroView from '../views/apcadastro/Index.vue'

import SignInView from '../views/sign-in/Index.vue'
import HomeAppBar from '../views/home/AppBar.vue'
import HomeView from '../views/home/Index.vue'
import AbastecimentoAppBar from '../views/abastecimento/AppBar.vue'
import AbastecimentoView from '../views/abastecimento/Index.vue'
import CadastroAppBar from '../views/cadastro/AppBar.vue'
import CadastroView from '../views/cadastro/Index.vue'
import OrigemAppBar from '../views/origem/AppBar.vue'
import OrigemView from '../views/origem/Index.vue'
import PropriedadeAppBar from '../views/propriedade/AppBar.vue'
import PropriedadeView from '../views/propriedade/Index.vue'
import FrotaAppBar from '../views/frota/AppBar.vue'
import FrotaView from '../views/frota/Index.vue'
import FrotaFilter from '../views/frota/Filters.vue'
import OperadorView from '../views/operador/Index.vue'
import OperadorAppBar from '../views/operador/AppBar.vue'
import OperadorFilter from '../views/operador/Filters.vue'
import ReadOnlyView from '../views/readonly/Index.vue'
import ReadOnlyAppBar from '../views/readonly/AppBar.vue'
import AbastecimentoFilter from '../views/abastecimento/Filters.vue'

import VisitaView from '../views/visita/Index.vue'
import VisitaAppBar from '../views/visita/AppBar.vue'
import VisitaFilter from '../views/visita/Filters.vue'
import VisitaCadastroAppBar from '../views/visitacadastro/AppBar.vue'
import VisitaCadastroView from '../views/visitacadastro/Index.vue'
import VisitaInfoAppBar from '../views/visitainfo/AppBar.vue'
import VisitaInfoView from '../views/visitainfo/Index.vue'
import CameraAppBar from '../views/camera/AppBar.vue'
import CameraView from '../views/camera/VisitaIndex.vue'

import NFeAppBar from '../views/nfe/AppBar.vue';
import NFeView from '../views/nfe/Index.vue';
import NFeFilter from '../views/nfe/Filters.vue';
import CadastroNFeAppBar from '../views/cadastronfe/AppBar.vue';
import CadastroNFeView from '../views/cadastronfe/Index.vue';

import { ROUTE_SIGN_IN } from '@/constants/ROUTES';
import { TOKENLOCAL } from '@/constants/STORAGE';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

function validaToken(rota, next) {
  const token = getSessionStorage(TOKENLOCAL.tokenlocal);

  if (!token) {
    next({ path: rota });
  } else {
    next();
  }
}

// import BaseLayout from '../layouts/base/Index.vue'

Vue.use(VueRouter)

// viewRoute({
//   name: ROUTE_HOME.name,
//   view: View,
//   appBar: AppBar,
// }, requireAuthenticated),

const routes = [
  {
    path: '/home',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: HomeAppBar,
        default: HomeView,
      },
      name: 'home',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/abastecimento',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: AbastecimentoAppBar,
        default: AbastecimentoView,
        filters: AbastecimentoFilter,
      },
      name: 'abastecimento',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/visita',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: VisitaAppBar,
        filters: VisitaFilter,
        default: VisitaView
      },
      name: 'visita',
      path: '',
      beforeEnter: ((to,from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next);
      }),
    }],
  },
  {
    path: '/visita/cadastro',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: VisitaCadastroAppBar,
        default: VisitaCadastroView,
      },
      name: 'visitacadastro',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next);
      })
    }]
  },
  {
    path: '/visita/info',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: VisitaInfoAppBar,
        default: VisitaInfoView,
      },
      name: 'visitainfo',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next);
      })
    }]
  },
  {
    path: '/visita/camera',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: CameraAppBar,
        default: CameraView,
      },
      name: 'camera',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next);
      })
    }]
  },
  {
    path: '/nfe',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: NFeAppBar,
        filters: NFeFilter,
        default: NFeView,
      },
      name: 'nfe',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken(ROUTE_SIGN_IN.path, next);
      })
    }]
  },
  {
    path: '/cadastronfe',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: CadastroNFeAppBar,
        default: CadastroNFeView,
      },
      name: 'cadastronfe',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken(ROUTE_SIGN_IN.name, next);
      }),
    }],
  },
  {
    path: '/apontamento',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: ApontamentoAppBar,
        default: ApontamentoView,
        filters: ApontamentoFilter,
      },
      name: 'apontamento',
      path: '',
      beforeEnter: ((to,from, next) => {
        validaToken(ROUTE_SIGN_IN.path, next);
      })
    }]
  },
  {
    path: '/apontamento/cadastro',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: ApCadastroAppBar,
        default: ApCadastroView
      },
      name: 'apcadastro',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken(ROUTE_SIGN_IN.path, next);
      }),
    }],
  },
  {
    path: '/abastecimento/cadastro',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: CadastroAppBar,
        default: CadastroView,
      },
      name: 'cadastro',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/abastecimento/cadastro/origem',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: OrigemAppBar,
        default: OrigemView,
      },
      name: 'origem',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/abastecimento/cadastro/propriedade',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: PropriedadeAppBar,
        default: PropriedadeView,
      },
      name: 'propriedade',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/abastecimento/cadastro/frota',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: FrotaAppBar,
        default: FrotaView,
        filters: FrotaFilter,
      },
      name: 'frota',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/abastecimento/cadastro/operador',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: OperadorAppBar,
        default: OperadorView,
        filters: OperadorFilter,
      },
      name: 'operador',
      path: '',
      beforeEnter: ((to, from, next) => {
        sessionStorage.setItem(ROTA_STORAGE.anterior, from.name);
        sessionStorage.setItem(ROTA_STORAGE.proxima, to.name);
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/abastecimento/readonly',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: ReadOnlyAppBar,
        default: ReadOnlyView,
      },
      name: 'readonly',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/',
    name: 'signin',
    component: SignInView,
    beforeEnter: ((to, from, next) => {
      const token = sessionStorage.getItem('auth:tokenlocal', null)
      if(token) {
        router.replace('/home')
      }
      next();
    }),
  },
  redirectRoute('/'),
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export function redirectRoute(redirect) {
  return {
    path: '*',
    redirect,
  };
}

export default router

